<template>
  <div class="flex flex-col w-full my-2 text-input">
    <p class="px-3 text-lg font-extrabold text-left text-primary">{{label}}</p>
    <div class="flex justify-between w-full">
      <p class="`
        flex-grow px-3 py-1 mx-2 font-bold bg-transparent rounded-lg focus:outline-none
        text-left
      `">
        {{value}}
      </p>
      <button
        v-if="!disabled"
        @click="$emit('click')"
        :class="`
          px-3 py-1 mx-2 text-lg font-semibold rounded-lg
          ${color} focus:outline-none
        `"
      >
        {{buttonText}}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonEditData',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonColor: {
      type: String,
      required: true,
      validate: (val) => ['red', 'background'].include(val),
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    color() {
      return this.buttonColor === 'background'
        ? 'bg-background text-content'
        : `bg-${this.buttonColor}-500 text-white`;
    },
  },
};
</script>
