<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      :show-right-panel="false"
      :modal-show="modal.deleteAccount"
    >

      <template #modal>
        <modal-confirmation
          v-if="modal.deleteAccount"
          title="Delete Your Account?"
          description="If you delete your account all of your data will be lost!"
          text-confirm="Delete"
          text-cancel="Cancel"
          @confirm="confirmDeleteAccount"
          @cancel="modal.deleteAccount = false"
        />

      </template>

      <template #title>
        Settings
      </template>

      <template #content>
        <div class="`
          flex flex-col items-stretch self-center h-full pt-10
          w-5/6 phone:w-3/4 md:w-2/3 lg:w-3/5 max-w-md
          mb-10 pb-10
        `">

          <!-- Top portion -->
          <div class="flex items-center flex-shrink-0 w-full mb-4">
            <circular-image-component
              :picture="picture"
            />
            <div class="flex flex-col h-16 pl-3 text-left">
              <p class="text-2xl font-extrabold text-primary">
                {{user.name}}
              </p>
              <p class="flex font-extrabold text-content">
                {{user.email}}
              </p>

            </div>
          </div>

          <!-- User Info Box -->
          <div class="w-full p-3 rounded-lg bg-backfill ">
            <text-input-component
              @save="updateUser"
              :value.sync="user.name"
              :valid="user.name.length > 0"
              label="Name"
              mode="edit"
            />
            <text-input-component
              @save="updateUser"
              label="Country"
              :value.sync="user.country"
              mode="edit"
              :valid="true"
            />
            <text-input-component
              @save="updateUser"
              label="Postal / Zip Code"
              :value.sync="user.postal_code"
              type="postalCode"
              mode="edit"
              :valid="true"
            />

            <button-edit-data
              @click="modal.deleteAccount = true"
              label="Account Status"
              value="Active"
              button-color="red"
              button-text="Delete"
            />

          </div>

          <!-- Middle box -->
          <div class="w-full p-3 mt-5 rounded-lg bg-backfill">
            <button-inline
              @click="takePicture"
              label="Profile Picture"
              :value="'Update'"
              :disabled="false"
            />
            <input @change="replacePicture" v-show='false' type="file" accept="image/*" />
            <button-inline
              @click="darkModeOn = !darkModeOn"
              label="Dark Mode"
              :value="darkModeOn ? 'On' : 'Off'"
              :disabled="false"
            />

          </div>

          <!-- Bottom box -->
          <div class="w-full p-3 mt-5 mb-10 rounded-lg bg-backfill">
            <button-edit-data
              @click="$router.push({name: 'PaymentPlan'})"
              label="Account Type"
              :disabled="user.plan === 'Premium'"
              :value="user.plan || 'Free'"
              button-color="background"
              button-text="Upgrade"
            />
          </div>

          <button-large
            @click="logout"
            color="primary"
            class="self-center"
          >
            Log Out
          </button-large>

          <div class="h-10 opacity-0">space...</div>

        </div>
      </template>

    </interior-page-content-container>
</template>

<script>

import { USER_DELETE, USER_EDIT } from '@/store/actions';
import { USER_STORE_DETAILS, USER_REMOVE_DETAILS, USER_REMOVE_TOKEN } from '@/store/mutations';
import { USER_DETAILS } from '@/store/getters';
import { getDarkMode } from '@/utils/darkMode';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import TextInputComponent from '@/components/shared/inputs/TextInputComponent.vue';
import ModalConfirmation from '@/components/shared/inputs/ModalConfirmation.vue';
import CircularImageComponent from '@/components/shared/general/CircularImageComponent.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';
import ButtonEditData from '@/components/shared/Buttons/ButtonEditData.vue';
import ButtonInline from '@/components/shared/Buttons/ButtonInline.vue';

import * as countryJson from '../assets/json/countries.json';

export default {
  name: 'Settings',
  components: {
    InteriorPageContentContainer,
    ModalConfirmation,
    TextInputComponent,
    CircularImageComponent,
    ButtonLarge,
    ButtonEditData,
    ButtonInline,
  },
  data() {
    return {
      countries: countryJson.countries,
      defaultPicture: {
        url: '/img/general-defaults/user.jpeg',
      },
      newPicture: null,
      darkModeOn: getDarkMode(),
      dummy: true,
      modal: {
        deleteAccount: false,
      },
    };
  },
  computed: {
    picture() {
      if (this.newPicture) return URL.createObjectURL(this.newPicture);
      return this.user.picture.url;
    },
    user() {
      const user = {
        picture: this.defaultPicture,
        ...this.$store.getters[USER_DETAILS],
      };
      if (!user.postal_code) user.postal_code = '';
      if (!user.country) user.country = '';
      return user;
    },
  },
  watch: {
    darkModeOn: {
      handler() {
        this.$store.commit(
          'SET_DARK_MODE',
          !this.$store.getters.GET_DARK_MODE,
        );
      },
    },
  },
  methods: {
    backNav() {
      this.$router.go(-1);
    },
    logout() {
      this.$store.commit(USER_REMOVE_DETAILS);
      this.$store.commit(USER_REMOVE_TOKEN);
      this.$router.push({ name: 'Landing' });
    },
    confirmDeleteAccount() {
      this.$store.dispatch(USER_DELETE)
        .then(() => {
          this.$router.push({ name: 'Landing' });
        });
    },
    updateUser() {
      const body = { ...this.user };
      if (this.newPicture) body.picture = this.newPicture;
      else delete body.picture;
      this.$store.commit(USER_STORE_DETAILS, {
        ...body,
        picture: { url: this.picture },
      });
      this.$store.dispatch(USER_EDIT, body);
    },
    takePicture() {
      document.querySelector('input[type="file"]').click();
    },
    replacePicture(event) {
      // eslint-disable-next-line prefer-destructuring
      this.newPicture = event.target.files[0];
      this.$store.commit(USER_STORE_DETAILS, {
        picture: { url: URL.createObjectURL(this.newPicture) },
      });
      this.updateUser();
    },
  },
};
</script>

<style>

</style>
